import React, { FunctionComponent } from 'react'
import Select from 'react-select'
import { dt } from 'Shared/utils/dynamicRS'
import { t } from 'ttag'

import {
  FilterInputType,
  getDataByKey,
  getLocalizedSelectValue,
} from '../PuxRepeater'

const rootClass = `partner-filter`

const PartnerFilter: FunctionComponent<FilterInputType> = (props) => {
  const data = props.completeData

  const rsSolution = dt(`Pux.RepeaterFilter.Partner.Country`)
  const rsLevel = dt(`Pux.RepeaterFilter.Partner.Level`)

  const countryData = getDataByKey(data, `partnerCountry`, rsSolution)
  const levelData = getDataByKey(data, `partnerLevel`, rsLevel)

  props.setBindingTable({
    'filter-country': {
      field: `partnerCountry`,
    },
    'filter-level': {
      field: `partnerLevel`,
    },
  })

  return (
    <div className={`pux-repeater-filter ${rootClass}-wrapper`}>
      <div className={`pux-repeater-filter-item`}>
        <label htmlFor='filter-country'>
          {t`Pux.RepeaterFilter.Partner.Country.Label`}
        </label>
        <Select
          name='filter-country'
          className='react-select-container'
          classNamePrefix='react-select'
          value={getLocalizedSelectValue(
            props.activeFilters[`partnerCountry`],
            `Pux.RepeaterFilter.Partner.Country`
          )}
          options={countryData}
          onChange={(selectedOption) =>
            props.updateUrl(selectedOption.value, `filter-country`)
          }
        />
      </div>
      <div className={`pux-repeater-filter-item`}>
        <label htmlFor='filter-level'>
          {t`Pux.RepeaterFilter.Partner.Level.Label`}
        </label>
        <Select
          name='filter-level'
          className='react-select-container'
          classNamePrefix='react-select'
          value={getLocalizedSelectValue(
            props.activeFilters[`partnerLevel`],
            `Pux.RepeaterFilter.Partner.Level`
          )}
          options={levelData}
          onChange={(selectedOption) =>
            props.updateUrl(selectedOption.value, `filter-level`)
          }
        />
      </div>
      {/* <input type='button' onClick={props.clearFilter} value='clear'></input> */}
    </div>
  )
}

export default PartnerFilter
